export function evictApolloCache(fieldNames: Array<string>|string): void {
  if (typeof fieldNames === 'string') {
    useApolloClient().client.cache.evict({fieldNames});
    return;
  }

  fieldNames.forEach((fieldName) => {
    useApolloClient().client.cache.evict({fieldName});
  });
}
